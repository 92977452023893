import { Ifo } from './types'

const ifos: Ifo[] = [
{
    id: 'bzfi',
    address: '0xCF83356e42B01D06aA51F3e8906B1f67577E7D6e',
    isActive: true,
    name: 'BitZyon Finance',
    subTitle: 'BitZyon Finance (BZFI) was created with the sole purpose of maximizing investments in a safe and sustainable ecosystem.',
    description:
      'Built on the Binance Smart Chain (BEP-20) network and with the concept of Decentralised Finance (Defi), users will have the opportunity to generate profits while owning BitZyon Finance (BZFI).',
    launchDate: 'Jul 20 00:00 GMT-0300',
    launchTime: '15:30 GMT-0300',
    saleAmount: '786,000 BZFI',
    saleInfo: '0.025 LP = 1 BZFI',
    raiseAmount: '~$432,300',
    cakeToBurn: '~$216,150',
    projectSiteUrl: 'https://medium.com/zcore/zcore-finance-ifo-bitzyon-finance-bzfi-578ddb61bc34',
    currency: 'ZEFI-BNB',
    currencyAddress: '0x7C020B60fBDeB668834BE0367CfBA395CaAE5dFc',
    tokenDecimals: 18,
    tokenSymbol: 'BZFI',
    releaseBlockNumber: 9310823,
    campaignId: '511110000',
  },  
  {
    id: 'zboost',
    address: '0x3Ec8b4a4D22f3DC1C6a264AA35e961584B77BBE7',
    isActive: false,
    name: 'ZBoost',
    subTitle: 'ZBoost is a new token aimed at increasing ZEFI burning pace and serving other burning related purposes in the platform.',
    description: 'ZBOOST is a deflationary token that gets burned with every transaction. It also drives ZEFI’s burning mechanism, helping decrease it’s continuous inflation.',
    launchDate: 'May 20',
    launchTime: '21:00 GMT-0300',
    saleAmount: '100,000 ZBO',
    saleInfo: '0.3 LP = 1 ZBO',
    raiseAmount: '~$2,000,000',
    cakeToBurn: '~$1,000,000',
    projectSiteUrl: 'https://medium.com/zcore/zboost-zcore-finance-card-changes-and-app-update-8c9ceaa5b720',
    currency: 'ZEFI-BNB',
    currencyAddress: '0x7C020B60fBDeB668834BE0367CfBA395CaAE5dFc',
    tokenDecimals: 18,
    tokenSymbol: 'ZBO',
    releaseBlockNumber: 7583059,
    campaignId: '511100000',
  },
{
    id: 'goal',
    address: '0xF3Cf2ffEba2938eaA4674fC24ce41f3fb2F09764',
    isActive: false,
    name: 'Fanatics Finance',
    subTitle: 'All football fanatics now have their place reserved in the DeFi gallery!',
    description:
      'Goal is a token created within the BSC (Binance Smart Chain) protocol that will serve as fanaticsfinance issuance and rewards, exclusive DeFi for fan tokens, football club tokens and sports entities. All football fanatics now have their place reserved in the DeFi gallery!',
    launchDate: 'April. 28',
    launchTime: '15:30 GMT-0300',
    saleAmount: '90,000 GOAL',
    saleInfo: '10 LP = 1 GOAL',
    raiseAmount: '~$1,800,000',
    cakeToBurn: '~$900,000',
    projectSiteUrl: 'http://fanaticsfinance.com/',
    currency: 'ZEFI-BUSD LPv2',
    currencyAddress: '0x09b0123e36a2b0a5b95474455e437e8861a6c61e',
    tokenDecimals: 18,
    tokenSymbol: 'GOAL',
    releaseBlockNumber: 6965617,
    campaignId: '511090000',
  },  
  
]

export default ifos
