import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { getBalanceNumber } from 'utils/formatBalance'
import useI18n from 'hooks/useI18n'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Flex, MetamaskIcon, Text, Progress } from '@pancakeswap-libs/uikit'
import Balance from 'components/Balance'
import BalanceBlock from 'components/BalanceBlock'
import { CommunityTag, CoreTag, BinanceTag } from 'components/Tags'
import { useBlock } from 'state/hooks'
import { PoolCategory } from 'config/constants/types'
import { registerToken } from 'utils/wallet'
import { BASE_URL } from 'config'

const tags = {
  [PoolCategory.BINANCE]: BinanceTag,
  [PoolCategory.CORE]: CoreTag,
  [PoolCategory.COMMUNITY]: CommunityTag,
}

interface Props {
  projectLink: string
  decimals: number
  totalStaked: BigNumber
  tokenName: string
  tokenAddress: string
  tokenDecimals: number
  startBlock: number
  endBlock: number
  isFinished: boolean
  poolCategory: PoolCategory
  stakingToken: string
  stakingTokenPrice: number
}

const StyledFooter = styled.div<{ isFinished: boolean }>`
  border-top: 1px solid ${({ theme }) => (theme.isDark ? '#524B63' : '#E9EAEB')};
  color: ${({ isFinished, theme }) => theme.colors[isFinished ? 'textDisabled2' : 'primary2']};
  padding: 24px;
`

const StyledDetailsButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: 0;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  outline: 0;
  padding: 0;
  &:hover {
    opacity: 0.9;
  }

  & > svg {
    margin-left: 4px;
  }
`

const Details = styled.div`
  margin-top: 24px;
`

const Row = styled(Flex)`
  align-items: center;
`

const FlexFull = styled.div`
  flex: 1;
`
const Label = styled.div`
  font-size: 14px;
`
const TokenLink = styled.a`
  font-size: 14px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`

const ProgressWrapper = styled.div`
  display: block;
  width: 100%;
`

const CardFooter: React.FC<Props> = ({
  projectLink,
  decimals,
  tokenAddress,
  totalStaked,
  tokenName,
  tokenDecimals,
  isFinished,
  startBlock,
  endBlock,
  poolCategory,
  stakingToken,
  stakingTokenPrice,
}) => {
  const { blockNumber: currentBlock } = useBlock()
  const [isOpen, setIsOpen] = useState(false)
  const TranslateString = useI18n()
  const Icon = isOpen ? ChevronUp : ChevronDown

  const handleClick = () => setIsOpen(!isOpen)
  const Tag = tags[poolCategory]

  const blocksUntilStart = Math.max(startBlock - currentBlock, 0)
  const blocksRemaining = Math.max(endBlock - currentBlock, 0)
  const days = (blocksRemaining/28800)
  const daysRem = (blocksUntilStart/28800)

  // const imageSrc = `https://zefi.zcore.network/images/tokens/${tokenName.toLowerCase()}.png`
  const imageSrc = `https://zcore.network/coins/${tokenName.toUpperCase()}.png`
  const barra = 100 - ((blocksRemaining * 100) / (endBlock - startBlock))



  const totalTVL = (getBalanceNumber(totalStaked, decimals) * stakingTokenPrice)
  const oldtotalTVL = getBalanceNumber(totalStaked, decimals)

  // console.log(getBalanceNumber(totalStaked, decimals))

/*
   console.log('==================================')
   console.log(tokenName)
   // console.log(endBlock - startBlock)
   console.log(totalStaked)
   // console.log(blocksRemaining)
   // console.log(barra.toFixed(0))
   console.log('==================================')
*/

  return (
    <StyledFooter isFinished={isFinished}>
      <Row>
        <FlexFull>
          <Tag />
        </FlexFull>
        <StyledDetailsButton onClick={handleClick}>
          {isOpen ? TranslateString(1066, 'Hide') : TranslateString(658, 'Details')} <Icon />
        </StyledDetailsButton>
      </Row>
      {isOpen && (  
        <Details>







          <Row mb="10px">             
            <FlexFull>
              <Label>
                <span role="img" aria-label="syrup">
                  🚜 {' '}
                </span>
                Total Stake:
              </Label>
            </FlexFull>
            <Balance fontSize="14px" isDisabled={isFinished} decimals={2} value={oldtotalTVL} />
              &nbsp;
              <Text fontSize="14px">
                {stakingToken}
              </Text>            
          </Row>


          {blocksUntilStart > 0 && (
            <Row mb="20px">
              <FlexFull>
                <Label>↗️ {TranslateString(1212, 'Start')}:</Label>
              </FlexFull>
              <BalanceBlock fontSize="14px" isDisabled={isFinished} value={blocksUntilStart} decimals={0} />
              &nbsp;
              <Text fontSize="14px">
                - {daysRem.toFixed(0)} day(s)
              </Text>
            </Row>
          )}
          {blocksUntilStart === 0 && blocksRemaining > 0 && (
       <>
            <Row mb="20px">
              <FlexFull>
                <Label>↗️ {TranslateString(410, 'End')}:</Label>
              </FlexFull>
              <BalanceBlock fontSize="14px" isDisabled={isFinished} value={blocksRemaining} decimals={0} />
            </Row>
       
        <Row mb="10px">
        <FlexFull>
            <Label>⌛ {days.toFixed(0)} day(s):</Label>
         </FlexFull>
        </Row>
        <Row mb="20px">
        <FlexFull>
            <ProgressWrapper>
               <Progress primaryStep={barra} showProgressBunny />   
           </ProgressWrapper>
         </FlexFull>
        </Row>

        </>
          )}



          {tokenAddress && (
            <Flex mb="10px">
              <TokenLink onClick={() => registerToken(tokenAddress, tokenName, tokenDecimals, imageSrc)}>
                Add {tokenName} to Metamask
              </TokenLink>
              <MetamaskIcon height={15} width={15} ml="4px" />
            </Flex>
          )}
          <TokenLink href={projectLink} target="_blank">
            {TranslateString(412, 'View project site')} 🌐
          </TokenLink>
        </Details>
      )}
    </StyledFooter>
  )
}

export default React.memo(CardFooter)
