export default {
  masterChef: {
    97: '',
    56: '0x05a8ba2DEf87f8AdaF223Edcd04F7Fc82eA6aee8',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lottery: {
    97: '',
    56: '0xC946A04C1945a1516ED3CF07974ce8Dbd4D19005',
  },
  lotteryNFT: {
    97: '',
    56: '0x0bc15a0b805E2012c29B95C85Bf34E3cF4DCdB35',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '',
  },
  cakeVault: {
    56: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
    97: '',
  },  
}
