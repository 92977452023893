import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [

  {
    pid: 40,
    lpSymbol: 'ZEFI-BNB LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x7C020B60fBDeB668834BE0367CfBA395CaAE5dFc',
    },
    token: tokens.zefi,
    quoteToken: tokens.wbnb,
  }, 
  {
    pid: 50,
    lpSymbol: 'ZEFI-BTCB LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x5Cb8085d054AD6dEACBC3aF15D718191f6493582',
    },
    token: tokens.btcb,
    quoteToken: tokens.zefi,
  },
  {
    pid: 59,
    lpSymbol: 'ZEFI-ETH LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x33BF7C79Ccce15f2d53a7D8ADF5Ca3030CD2764C',
    },
    token: tokens.eth,
    quoteToken: tokens.zefi,
  },
  {
    pid: 39,
    lpSymbol: 'ZEFI-BUSD LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x09B0123e36A2b0A5b95474455E437e8861a6C61E',
    },
    token: tokens.zefi,
    quoteToken: tokens.busd,
  },      
  {
    pid: 41,
    lpSymbol: 'ZEFI-USDT LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x7C61b6Fd466297C8Ef493b3a52BDc0D4A055185F',
    },
    token: tokens.zefi,
    quoteToken: tokens.usdt,
  },  
  {
    pid: 67,
    lpSymbol: 'ZEFI-ZBO LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x3843CEA01b025973d76cEaB6dA2F5F1E52106687',
    },
    token: tokens.zbo,
    quoteToken: tokens.zefi,
  },
  {
    pid: 66,
    lpSymbol: 'ZBO-BNB LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x0418dE230ed174D99e80cE6cBC398cc97CD31756',
    },
    token: tokens.zbo,
    quoteToken: tokens.wbnb,
  },  

  {
    pid: 0,
    lpSymbol: 'WZCR-BUSD-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x706040E6472BF5445e7B88D450De4F2623106DB2',
    },
    token: tokens.wzcr,
    quoteToken: tokens.busd,
  },   
  {
    pid: 49,
    lpSymbol: 'WZCR-BUSD LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0xb81243d9841620C1d6bcD81BB3F1a3F98940d303',
    },
    token: tokens.wzcr,
    quoteToken: tokens.busd,
  },      
  {
    pid: 2,
    lpSymbol: 'ZCRT-BUSD-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x30599d4c9c015bf6cb374cf9a125b3c94d101f69',
    },
    token: tokens.zcrt,
    quoteToken: tokens.busd,
  },  
  {
    pid: 48,
    lpSymbol: 'ZCRT-BUSD LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0xe7f8b32e40a1a8abb95a29a54575c5c014cbeb2f',
    },
    token: tokens.zcrt,
    quoteToken: tokens.busd,
  },      
  {
    pid: 3,
    lpSymbol: 'BNB-BUSD-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
    },
    token: tokens.wbnb,
    quoteToken: tokens.busd,
  },   
  {
    pid: 42,
    lpSymbol: 'BNB-BUSD LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },     
  {
    pid: 4,
    lpSymbol: 'USDT-BUSD-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0xc15fa3e22c912a276550f3e5fe3b0deb87b55acd',
    },
    token: tokens.usdt,
    quoteToken: tokens.busd,
  },   
  {
    pid: 5,
    lpSymbol: 'USDC-BUSD-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x680dd100e4b394bda26a59dd5c119a391e747d18',
    },
    token: tokens.usdc,
    quoteToken: tokens.busd,
  },  
  {
    pid: 43,
    lpSymbol: 'USDC-BUSD LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x2354ef4df11afacb85a5c7f98b624072eccddbb1',
    },
    token: tokens.usdc,
    quoteToken: tokens.busd,
  },     
  {
    pid: 22,
    lpSymbol: 'DAI-BUSD-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x3aB77e40340AB084c3e23Be8e5A6f7afed9D41DC',
    },
    token: tokens.dai,
    quoteToken: tokens.busd,
  },  
  {
    pid: 12,
    lpSymbol: 'BTCB-BNB-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x7561eee90e24f3b348e1087a005f78b4c8453524',
    },
    token: tokens.btcb,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 13,
    lpSymbol: 'ETH-BNB-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x70d8929d04b60af4fb9b58713ebcf18765ade422',
    },
    token: tokens.eth,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 6,
    lpSymbol: 'CAKE-BNB-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0xa527a61703d82139f8a06bc30097cc9caa2df5a6',
    },
    token: tokens.cake,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 27,
    lpSymbol: 'WLQX-ZEFI-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x39529BFf33813151529E71f8bF8C6F1fCcBEb0EB',
    },
    token: tokens.wlqx,
    quoteToken: tokens.zefi,
  },
  {
    pid: 44,
    lpSymbol: 'WLQX-ZEFI LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x23f0fa0b1a8fc96fedb11c629df22dc1e892a51a',
    },
    token: tokens.wlqx,
    quoteToken: tokens.zefi,
  },  
  {
    pid: 21,
    lpSymbol: 'WLQX-BNB-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x96c3Bef501F5ddC82e0717839Dba5F4e1F75429d',
    },
    token: tokens.wlqx,
    quoteToken: tokens.wbnb,
  },    
  {
    pid: 45,
    lpSymbol: 'WLQX-BNB LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x91649446095135ad5e0b597fe8069f0922ce5c7e',
    },
    token: tokens.wlqx,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 70,
    lpSymbol: 'BZFI-BNB LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x908ff77b11c7a6f989e2501413908a6dbe45b7c4',
    },
    token: tokens.bzfi,
    quoteToken: tokens.wbnb,
  },    
  {
    pid: 55,
    lpSymbol: 'GOAL-ZEFI LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0xBA7dFa93cD9c800B5a766E92D49E1Cf4470d4224',
    },
    token: tokens.goal,
    quoteToken: tokens.zefi,
  },     
  {
    pid: 29,
    lpSymbol: 'POLIS-ZEFI-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x51E2BbF287e3D82b0c0F7Cbc4719F67B59Cd8D34',
    },
    token: tokens.polis,
    quoteToken: tokens.zefi,
  },
  {
    pid: 30,
    lpSymbol: 'POLIS-BUSD-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x8AF17cC0ADb1559bf0151D7AE291cE6316c37612',
    },
    token: tokens.polis,
    quoteToken: tokens.busd,
  },   
  {
    pid: 51,
    lpSymbol: 'POLIS-ZEFI LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0xcd890c4417de0f04b5827215e1bfe6171bcf45ad',
    },
    token: tokens.polis,
    quoteToken: tokens.zefi,
  },
  {
    pid: 52,
    lpSymbol: 'POLIS-BUSD LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0xc0507b3a35917146873363911540ca5eca600256',
    },
    token: tokens.polis,
    quoteToken: tokens.busd,
  },     
  {
    pid: 57,
    lpSymbol: 'WIQ-ZEFI LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x1Bbb4Ea0B366966dc595E6C2c8843210AE98276e',
    },
    token: tokens.wiq,
    quoteToken: tokens.zefi,
  },
  {
    pid: 63,
    lpSymbol: 'WIQ-BNB LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x41465263b7577B3Fb44bff631EbF1EE24402a1e7',
    },
    token: tokens.wiq,
    quoteToken: tokens.wbnb,
  },    
  {
    pid: 62,
    lpSymbol: 'WIQ-BUSD LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0xCd4a9e8AcFE6B11C27a11ff9484af3DFf5aAA1Be',
    },
    token: tokens.wiq,
    quoteToken: tokens.busd,
  },      
  {
    pid: 58,
    lpSymbol: 'NULS-ZEFI LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x58C086C2f075fc313F7E614386aCba7E9cfb109E',
    },
    token: tokens.nuls,
    quoteToken: tokens.zefi,
  },  
  {
    pid: 53,
    lpSymbol: 'CBRL-ZEFI LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x7957F8E8de057F0D0952b62AE81274d7A627e6C2',
    },
    token: tokens.cbrl,
    quoteToken: tokens.zefi,
  },
  {
    pid: 54,
    lpSymbol: 'CBRL-BNB LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0xB3f2BDdb3006146E725F4fa7D78d1C5364F93836',
    },
    token: tokens.cbrl,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 32,
    lpSymbol: 'CBRL-ZEFI-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0xf3f1d8FA3edEe09bE8c7fE3c5902216A4ae23659',
    },
    token: tokens.cbrl,
    quoteToken: tokens.zefi,
  },
  {
    pid: 33,
    lpSymbol: 'CBRL-BNB-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x3E820DF7D7086DE2D46d908d04c0A24968B32b94',
    },
    token: tokens.cbrl,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 34,
    lpSymbol: 'NULS-ZEFI-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x41Dd11D3f3256250f26D7aD42F7914D4F6a12520',
    },
    token: tokens.nuls,
    quoteToken: tokens.zefi,
  },
  {
    pid: 36,
    lpSymbol: 'NULS-BNB-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0xad7E515409e5a7d516411a85aCc88C5E993F570a',
    },
    token: tokens.nuls,
    quoteToken: tokens.wbnb,
  },  
  {
    pid: 37,
    lpSymbol: 'WKSOC-ZEFI-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x45b4020a901ad1608E7527fA8Bfb071a0D7410A3',
    },
    token: tokens.wksoc,
    quoteToken: tokens.zefi,
  },  
  {
    pid: 11,
    lpSymbol: 'WKSOC-BUSD-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0xcEf1AB7C9B5A5025d90BB4Dc0FF491941e038665',
    },
    token: tokens.wksoc,
    quoteToken: tokens.busd,
  },
  {
    pid: 38,
    lpSymbol: 'WZYON-ZEFI-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x006Ed561aBd48B0691c524D7e6c3D675c1037830',
    },
    token: tokens.wzyon,
    quoteToken: tokens.zefi,
  },   
  {
    pid: 47,
    lpSymbol: 'WZYON-ZEFI LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0xf4181bd433db7a2ef91021e3677dcfefc8eb01c0',
    },
    token: tokens.wzyon,
    quoteToken: tokens.zefi,
  },      
  {
    pid: 25,
    lpSymbol: 'WZYON-BNB-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0xD158589471683815E0E8Ce178E8804f42975D650',
    },
    token: tokens.wzyon,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 46,
    lpSymbol: 'WZYON-BNB LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x7df230277a13ecfdadaca0e4da6e5d571dbbb9c3',
    },
    token: tokens.wzyon,
    quoteToken: tokens.wbnb,
  },  

  {
    pid: 60,
    lpSymbol: 'WBTCT-ZEFI LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x0C1DAe111a5D4A2716C5E9b443D4a2d3C0eF9294',
    },
    token: tokens.wbtct,
    quoteToken: tokens.zefi,
  },  
  {
    pid: 61,
    lpSymbol: 'WBTCT-BUSD LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0xe85B946f9858F6C6BD355CDC51aFFEb5E987C5ef',
    },
    token: tokens.wbtct,
    quoteToken: tokens.busd,
  },  

  {
    pid: 35,
    lpSymbol: 'WBTCT-ZEFI-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x5F398D81415c4d1E0AeC384080876fD4E3Feda0d',
    },
    token: tokens.wbtct,
    quoteToken: tokens.zefi,
  },  

  {
    pid: 64,
    lpSymbol: 'WKSOC-ZEFI LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x0F07507a2FAA22F6baCE59bAbc0dE59d28b5F547',
    },
    token: tokens.wksoc,
    quoteToken: tokens.zefi,
  },  
  {
    pid: 65,
    lpSymbol: 'WKSOC-BUSD LP',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0xB90fD2d1a47b0cF3917e6275d89837bf97eEC540',
    },
    token: tokens.wksoc,
    quoteToken: tokens.busd,
  },

  {
    pid: 8,
    lpSymbol: 'ZEFI-BUSD-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x24b87c29e907d6329ed8fd6d7b2ecb074089093e',
    },
    token: tokens.zefi,
    quoteToken: tokens.busd,
  },  
  {
    pid: 9,
    lpSymbol: 'ZEFI-BNB-v1 LP',
    version: '(LPv1)',
    lpAddresses: {
      97: '',
      56: '0x0e6027880a04677bae5e4225000078ee92904bf6',
    },
    token: tokens.zefi,
    quoteToken: tokens.wbnb,
  },   


  {
    pid: 14,
    isTokenOnly: true,
    lpSymbol: 'ZEFI',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x7C020B60fBDeB668834BE0367CfBA395CaAE5dFc',  // ZEFI-BUSD LP
    },
    token: tokens.zefi,
    quoteToken: tokens.wbnb,
  },

  {
    pid: 68,
    isTokenOnly: true,
    lpSymbol: 'ZBO',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x0418dE230ed174D99e80cE6cBC398cc97CD31756',  // ZBO-BNB LP
    },
    token: tokens.zbo,
    quoteToken: tokens.wbnb,
  },

  {
    pid: 28,
    isTokenOnly: true,
    lpSymbol: 'WZCR',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0xb81243d9841620C1d6bcD81BB3F1a3F98940d303',  // WZCR-BUSD LP
    },
    token: tokens.wzcr,
    quoteToken: tokens.busd,
  },


  {
    pid: 31,
    isTokenOnly: true,
    lpSymbol: 'ZCRT',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x30599d4c9c015bf6cb374cf9a125b3c94d101f69',  // ZCRT-BUSD LP
    },
    token: tokens.zcrt,
    quoteToken: tokens.busd,
  },

  {
    pid: 15,
    isTokenOnly: true,
    lpSymbol: 'BUSD',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x24b87c29e907d6329ed8fd6d7b2ecb074089093e', // EGG-BUSD LP (BUSD-BUSD will ignore)
    },
    token: tokens.busd,
    quoteToken: tokens.busd,
  },

  {
    pid: 16,
    isTokenOnly: true,
    lpSymbol: 'WBNB',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',  // BNB-BUSD LP
    },
    token: tokens.wbnb,
    quoteToken: tokens.busd,
  },    



  {
    pid: 23,
    isTokenOnly: true,
    lpSymbol: 'DAI',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x3ab77e40340ab084c3e23be8e5a6f7afed9d41dc', // DAI-BUSD LP
    },
    token: tokens.dai,
    quoteToken: tokens.busd,
  },

  {
    pid: 24,
    isTokenOnly: true,
    lpSymbol: 'CAKE',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x0ed8e0a2d99643e1e65cca22ed4424090b8b7458', // CAKE-BUSD LP
    },
    token: tokens.cake,
    quoteToken: tokens.busd,
  },

  {
    pid: 17,
    isTokenOnly: true,
    lpSymbol: 'BTCB',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0xb8875e207ee8096a929d543c9981c9586992eacb', // BTCB-BUSD LP
    },
    token: tokens.btcb,
    quoteToken: tokens.busd,
  },    


    {
    pid: 18,
    isTokenOnly: true,
    lpSymbol: 'ETH',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0xd9a0d1f5e02de2403f68bb71a15f8847a854b494', // ETH-BUSD LP
    },
    token: tokens.eth,
    quoteToken: tokens.busd,
  },

  {
    pid: 19,
    isTokenOnly: true,
    lpSymbol: 'USDC',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x680dd100e4b394bda26a59dd5c119a391e747d18', // USDC-BUSD LP
    },
    token: tokens.usdc,
    quoteToken: tokens.busd,
  },

  {
    pid: 20,
    isTokenOnly: true,
    lpSymbol: 'USDT',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0xc15fa3e22c912a276550f3e5fe3b0deb87b55acd', // USDT-BUSD LP
    },
    token: tokens.usdt,
    quoteToken: tokens.busd,
  },    


  {
    pid: 56,
    isTokenOnly: true,
    lpSymbol: 'WLQX',
    version: '(LPv2)',
    lpAddresses: {
      97: '',
      56: '0x91649446095135ad5e0b597fe8069f0922ce5c7e',  // WLQX-BNB LP
    },
    token: tokens.wlqx,
    quoteToken: tokens.wbnb,
  },

]

export default farms
