import { useEffect, useMemo , useState } from 'react'
import BigNumber from 'bignumber.js'
import { kebabCase } from 'lodash'
import { useWeb3React } from '@web3-react/core'
import { Toast, toastTypes } from '@pancakeswap-libs/uikit'
import { useSelector, useDispatch } from 'react-redux'
import { Team } from 'config/constants/types'
import erc20 from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getWeb3NoAccount } from 'utils/web3'
import useRefresh from 'hooks/useRefresh'
import farmsConfig from 'config/constants/farms'
import orderBy from 'lodash/orderBy'
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'
import masterchefABI from 'config/abi/masterchef.json'
import partition from 'lodash/partition'

import {
  fetchFarmsPublicDataAsync,
  fetchPoolsPublicDataAsync,
  fetchPoolsUserDataAsync,
  push as pushToast,
  remove as removeToast,
  clear as clearToast,
  setBlock,
} from './actions'
import { State, Farm, Pool, Block, ProfileState, TeamsState, AchievementState, PriceState } from './types'
import { fetchProfile } from './profile'
import { fetchTeam, fetchTeams } from './teams'
import { fetchAchievements } from './achievements'
import { fetchPrices } from './prices'

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
    dispatch(fetchPoolsPublicDataAsync())
  }, [dispatch, slowRefresh])

  useEffect(() => {
    const web3 = getWeb3NoAccount()
    const interval = setInterval(async () => {
      const blockNumber = await web3.eth.getBlockNumber()
      dispatch(setBlock(blockNumber))
    }, 6000)

    return () => clearInterval(interval)
  }, [dispatch])
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  }
}

// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}



export const useVaultsValue = () => {


type ApiResponse = {
  56: {
    [key: string]: string
  }
}

const api = 'https://api.zcore.farm/tvl'
const [data, setData] = useState<ApiResponse | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(api)
        const res: ApiResponse = await response.json()

        setData(res)
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData()
  }, [setData])


  let price = 0
  if(data){


    const tottvl = JSON.stringify(data[56]);
    const allTvl = JSON.parse(tottvl);

      JSON.parse(tottvl, (key, value) => {
            if (typeof value === 'number') {
              price += value
            }
      });
/*
    tottvl = JSON.stringify(data[137]);
    allTvl = JSON.parse(tottvl);

      JSON.parse(tottvl, (key, value) => {
            if (typeof value === 'number') {
              price += value
            }
      });      
*/      
  }

  return new BigNumber(price)
}

export const useTotalValue = (): BigNumber => {
  const farms = useFarms()
  const bnbPrice = usePriceBnbBusd()
  const cakePrice = usePriceCakeBusd()

  const btcPrice = 0
  const ethPrice = 0

  let value = new BigNumber(0)
  let debugando

  // console.log(JSON.stringify(farms))

  for (let i = 0; i < farms.length; i++) {
    const farm = farms[i]
    if (farm.lpTotalInQuoteToken) {
      // if(farm.multiplier !== '0X'){

        debugando = `${farm.pid} : ${farm.lpSymbol} (${farm.quoteToken.symbol}) --> ${farm.lpTotalInQuoteToken}`
        // debugando = `id: ${farm.lpAddresses[56]} --> token0: ${farm.tokenSymbol} --> token1: ${farm.quoteTokenSymbol}`
      // }

      // console.log( '##############################' )
      // console.log( debugando )
      // console.log( '##############################' )

      let val
      if (farm.quoteToken.symbol === "wBNB") {
        val = bnbPrice.times(farm.lpTotalInQuoteToken)
      // } else if (farm.quoteToken.symbol === "CAKE") {
      //  val = cakePrice.times(farm.lpTotalInQuoteToken)
      } else if (farm.quoteToken.symbol === "ZEFI") {
        val = cakePrice.times(farm.lpTotalInQuoteToken)
      } else {
        val = farm.lpTotalInQuoteToken
      }

      value = value.plus(val)
    }
  }



// BARN
  const { account } = useWeb3React()
  const pools = usePools(account)
  let total  
  let totalPrice  
  let stakingTokenPrice  
  const zefiBusdPrice = useGetApiPrice("ZEFI")
  const zefiBnbBusdPrice = useGetApiPrice("ZEFI-BNB")
  const zboBnbPrice = useGetApiPrice("ZBO-BNB")

  // console.log( JSON.stringify(pools) )
  
  for (let i = 0; i < pools.length; i++) {

    if(pools[i].totalStaked){
      
      total = new BigNumber(pools[i].totalStaked).div(new BigNumber(10).pow(pools[i].stakingToken.decimals))
      // console.log( pools[i].stakingToken.decimals )
      // console.log( total.toNumber() )
      // console.log( new BigNumber(10).pow(pools[i].stakingToken.decimals).toNumber() )
    
      if(pools[i].stakingToken.symbol === "ZEFI"){
        stakingTokenPrice = zefiBusdPrice
      }else if(pools[i].stakingToken.symbol === "ZEFI-BNB"){
        stakingTokenPrice = zefiBnbBusdPrice
      }else if(pools[i].stakingToken.symbol === "ZBO-BNB"){
        stakingTokenPrice = zboBnbPrice
      }else{
        stakingTokenPrice = 0
      }

      totalPrice = total.toNumber() * stakingTokenPrice
      // console.log(`${pools[i].stakingToken.symbol} --> ${total.toNumber()} --> ${totalPrice}`)

      value = value.plus(totalPrice)
    }

  }

  return value
}


// Toasts
export const useToast = () => {
  const dispatch = useDispatch()
  const helpers = useMemo(() => {
    const push = (toast: Toast) => dispatch(pushToast(toast))

    return {
      toastError: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.DANGER, title, description })
      },
      toastInfo: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.INFO, title, description })
      },
      toastSuccess: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.SUCCESS, title, description })
      },
      toastWarning: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.WARNING, title, description })
      },
      push,
      remove: (id: string) => dispatch(removeToast(id)),
      clear: () => dispatch(clearToast()),
    }
  }, [dispatch])

  return helpers
}

// Profile

export const useFetchProfile = () => {
  const { account } = useWeb3React()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProfile(account))
  }, [account, dispatch])
}

export const useProfile = () => {
  const { isInitialized, isLoading, data, hasRegistered }: ProfileState = useSelector((state: State) => state.profile)
  return { profile: data, hasProfile: isInitialized && hasRegistered, isInitialized, isLoading }
}

// Teams

export const useTeam = (id: number) => {
  const team: Team = useSelector((state: State) => state.teams.data[id])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTeam(id))
  }, [id, dispatch])

  return team
}

export const useTeams = () => {
  const { isInitialized, isLoading, data }: TeamsState = useSelector((state: State) => state.teams)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTeams())
  }, [dispatch])

  return { teams: data, isInitialized, isLoading }
}

// Achievements

export const useFetchAchievements = () => {
  const { account } = useWeb3React()
  const dispatch = useDispatch()

  useEffect(() => {
    if (account) {
      dispatch(fetchAchievements(account))
    }
  }, [account, dispatch])
}

export const useAchievements = () => {
  const achievements: AchievementState['data'] = useSelector((state: State) => state.achievements.data)
  return achievements
}

// Prices
export const useFetchPriceList = () => {
  const { slowRefresh } = useRefresh()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchPrices())
  }, [dispatch, slowRefresh])
}

export const useGetApiPrices = () => {
  const prices: PriceState['data'] = useSelector((state: State) => state.prices.data)
  return prices
}

export const useGetApiPrice = (token: string) => {
  const prices = useGetApiPrices()
  const wiq = usePriceBnbbyPid(63)
  const ksoc = usePriceBnbbyPid(65)
  const lqx = usePriceBnbbyPid(45)
  const wbtct = usePriceBnbbyPid(61)
  const wzcr = usePriceBnbbyPid(49)
  const zbo = usePriceBnbbyPid(66)
  const bzfi = usePriceBnbbyPid(70)


  // let newPrice = prices[token.toLowerCase()]
  let newPrice = prices ? prices[token.toLowerCase()] : 0

    if (!prices || newPrice === undefined || newPrice === null) {
      newPrice = 0
    }
    
    if(token === "WZCR"){
      newPrice = wzcr.toNumber()
    }
    if(token === "WIQ"){
      newPrice = wiq.toNumber()
    }
    if(token === "WKSOC"){
      newPrice = ksoc.toNumber()
    }
    if(token === "LQX"){
      newPrice = lqx.toNumber()
    }
    if(token === "WBTCT"){
      newPrice = wbtct.toNumber()
    }      
    if(token === "ZBO"){
      newPrice = zbo.toNumber()
    }    
    if(token === "BZFI"){
      newPrice = bzfi.toNumber()
    }                        

  return newPrice
}







export const useGetApy = (token: string) => {

type ApiResponse = {
    [key: string]: string
}

const api = 'https://finance.zcore.network/apy'
const [data, setData] = useState<ApiResponse | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(api)
        const res: ApiResponse = await response.json()

        setData(res)
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData()
  }, [setData])

  let price = '0'
  if(data){
    price = data[token]
  }

  if(!price){
    price = '0'
  }
  
  return price
}



export const useGetApiPriceZeFi = (token: string) => {


type ApiResponse = {
  prices: {
    [key: string]: string
  }
}

const api = 'https://finance.zcore.network/price'
const [data, setData] = useState<ApiResponse | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(api)
        const res: ApiResponse = await response.json()

        setData(res)
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData()
  }, [setData])


  let price = new BigNumber(0)
  if(data){
    const totprices = JSON.stringify(data.prices);
    const contact = JSON.parse(totprices);
    // console.log(token)
    // console.log(contact[token])
    price = new BigNumber(contact[token])
    // price = new BigNumber(data.prices.ZEFI)
  }
  return price
}


export const usePriceZBoostBusd = (): BigNumber => {
  const zbo = usePriceBnbbyPid(66)

  let price = new BigNumber(0)
  price = new BigNumber(zbo)
  return price
}

export const usePriceZefiBNB = (): BigNumber => {

  const ZERO = new BigNumber(0)

  // const pid = 1 // CAKE-BNB LP
  // const bnbPriceUSD = usePriceBnbBusd()
  // const farm = useFarmFromPid(pid)
  // return farm.tokenPriceVsQuote ? bnbPriceUSD.times(farm.tokenPriceVsQuote) : ZERO

  const pid = 40 // ZEFI-BNB LP
  const farm = useFarmFromPid(pid)

  // console.log( JSON.stringify(farm) )

  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}


export const useLPZefiBnb = (): JSON => {


  const api = "https://finance.zcore.network/lp";
  const [responseLP, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(api);
        const responseJson = await response.json();
        setData(responseJson);
      } catch (error) {
        console.error("Unable to fetch price data:", error);
      }
    };

    fetchData();
  }, [setData]);  

  return responseLP
}


export const usePriceBnbbyPid = (pid: number) => {
  
  const thisFarm = useFarmFromPid(pid)
  const ZERO = new BigNumber(0)
  const bnbBusdPrice = usePriceBnbBusd()  

 
  const thisFarmPrice = thisFarm.tokenPriceVsQuote ? bnbBusdPrice.times(thisFarm.tokenPriceVsQuote) : ZERO
  const thisFarmPriceBUSD = thisFarm.tokenPriceVsQuote ? new BigNumber(thisFarm.tokenPriceVsQuote) : ZERO

  let thisPrice;

  if(thisFarm.quoteToken.symbol === "BUSD"){
    thisPrice = thisFarmPriceBUSD;
  }else{
    thisPrice = thisFarmPrice;
  }


  
  // console.log(bnbBusdFarm.tokenPriceVsQuote)
  // return cakeBusdPrice
    return thisPrice  

}

export const usePriceBnbBusd = (): BigNumber => {
  const bnbBusdFarm = useFarmFromPid(42)
  const ZERO = new BigNumber(0)

  return bnbBusdFarm.tokenPriceVsQuote ? new BigNumber(1).div(bnbBusdFarm.tokenPriceVsQuote) : ZERO
}


export const usePriceZefiBusd = (): BigNumber => {
  
  const bnbBusdPrice = usePriceBnbBusd()
  const zefiBnbFarm = useFarmFromPid(40)
  const ZERO = new BigNumber(0)

  // return zefiBusdFarm.tokenPriceVsQuote ? new BigNumber(1).div(zefiBusdFarm.tokenPriceVsQuote) : ZERO
  // return zefiBusdFarm.tokenPriceVsQuote ? new BigNumber(zefiBusdFarm.tokenPriceVsQuote) : ZERO

  const zefiBusdPrice = zefiBnbFarm.tokenPriceVsQuote ? bnbBusdPrice.times(zefiBnbFarm.tokenPriceVsQuote) : ZERO  

  return zefiBusdPrice

}


export const usePriceCakeBusd = (): BigNumber => {

  const ZERO = new BigNumber(0)
  const cakeBnbFarm = useFarmFromPid(40)
  const bnbBusdPrice = usePriceBnbBusd()

  // const wiq = usePriceBnbbyPid(63)
  // const ksoc = usePriceBnbbyPid(65)
  // const lqx = usePriceBnbbyPid(45)
  // const wbtct = usePriceBnbbyPid(61)
  // const wzcr = usePriceBnbbyPid(49)

  // const bnbBusdPrice = bnbBusdFarm.tokenPriceVsQuote ? new BigNumber(1).div(bnbBusdFarm.tokenPriceVsQuote) : ZERO
  const cakeBusdPrice = cakeBnbFarm.tokenPriceVsQuote ? bnbBusdPrice.times(cakeBnbFarm.tokenPriceVsQuote) : ZERO  

  // console.log(bnbBusdPrice.toNumber())
  // console.log(cakeBnbFarm.tokenPriceVsQuote)
  // return cakeBusdPrice
  return cakeBusdPrice

/*
type ApiResponse = {
  prices: {
    [key: string]: string
  }
}

const api = 'https://api.pancakeswap.com/api/v1/price'
const [data, setData] = useState<ApiResponse | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(api)
        const res: ApiResponse = await response.json()

        setData(res)
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData()
  }, [setData])

  let price = new BigNumber(0)
  if(data){
    price = new BigNumber(data.prices.ZEFI)
  }
  return price
*/
}




export const usePriceContract = (tokenA: string, tokenB: string, lpAddress: string) => {


type DataResponse = {
          tokenBalanceLP: BigNumber,  
          quoteTokenBlanceLP: BigNumber,  
          tokenAmount: BigNumber,  
          quoteTokenAmount: BigNumber,  
          lpTotalSupply: BigNumber,  
          lpTokenBalanceMC: BigNumber,  
          thisPrice: BigNumber,
}

const [data, setData] = useState<any>(null)
const bnbBusdPrice = usePriceBnbBusd()

  useEffect(() => {
    const fetchData = async () => {


      const keydb = `${tokenA}_${tokenB}_${lpAddress}`
      const zefiDb = sessionStorage.getItem(keydb)
      let tempData

      if(zefiDb == null){


      const calls = [
        // Balance of token in the LP contract
        {
          address: tokenA,
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Balance of quote token on LP contract
        {
          address: tokenB,
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Balance of LP tokens in the master chef contract
        {
          address: lpAddress,
          name: 'balanceOf',
          params: [getMasterChefAddress()],
        },
        // Total supply of LP tokens
        {
          address: lpAddress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: tokenA,
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: tokenB,
          name: 'decimals',
        },
      ]

      const [
        tokenBalanceLP,
        quoteTokenBlanceLP,
        lpTokenBalanceMC,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals,
      ] = await multicall(erc20, calls)

      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

      // Total value in staking in quote token value
      const lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(18))
        .times(new BigNumber(2))
        .times(lpTokenRatio)

      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      const tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
      const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(lpTokenRatio)


      const ZERO = new BigNumber(0)      

      let thisPrice;
      const tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP))

      if(tokenB.toLowerCase() === "0xe9e7cea3dedca5984780bafc599bd69add087d56"){
        thisPrice = tokenPriceVsQuote ? new BigNumber(tokenPriceVsQuote) : ZERO
      }else{
        thisPrice = tokenPriceVsQuote ? bnbBusdPrice.times(tokenPriceVsQuote) : ZERO
      }



        tempData = {
          tokenBalanceLP: new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)),
          quoteTokenBlanceLP: new BigNumber(quoteTokenBlanceLP).div(new BigNumber(10).pow(tokenDecimals)),
          tokenAmount: tokenAmount.toJSON(),
          quoteTokenAmount: quoteTokenAmount.toJSON(),
          lpTotalSupply: new BigNumber(lpTotalSupply).div(new BigNumber(10).pow(tokenDecimals)),
          lpTokenBalanceMC: new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(tokenDecimals)),
          thisPrice: new BigNumber(thisPrice),
        }

        // console.log( `Consultou: ${keydb}` )
        sessionStorage.setItem(keydb, JSON.stringify(tempData) );  // key and value both should be string or number;
        setData(tempData);

      }else{
        // console.log( 'Cache...' )
        // tempData = JSON.parse( zefiDb )
      }

     

    };

    fetchData();
  }, [setData, tokenA, tokenB, lpAddress, bnbBusdPrice]);  

  let newData = data

  if(newData === null){
    const datakeydb = `${tokenA}_${tokenB}_${lpAddress}`
    const dataDb = sessionStorage.getItem(datakeydb)
    newData = JSON.parse(dataDb)
  }
  return newData
  
}






export const usePriceContractAll = (tokenA: string, tokenB: string, lpAddress: string) => {


type DataResponse = {
          tokenBalanceLP: BigNumber,  
          quoteTokenBlanceLP: BigNumber,  
          tokenAmount: BigNumber,  
          quoteTokenAmount: BigNumber,  
          lpTotalSupply: BigNumber,  
          thisPrice: BigNumber,
          update: number,
}

const [data, setData] = useState<any>(null)
const bnbBusdPrice = usePriceBnbBusd()

  useEffect(() => {
    const fetchData = async () => {


      const keydbAll = `${tokenA}_${tokenB}_${lpAddress}_all`
      const zefiDb = sessionStorage.getItem(keydbAll)
      let tempData

      const agora = new Date().getTime()
      let ultimo
      

      if(zefiDb === null){
        ultimo = new Date().getTime()
      }else{
        const datzefiDb = JSON.parse( zefiDb )
        ultimo = datzefiDb.update
      }

      const diferenca = ((agora - ultimo)/1000)

/*
      console.log('##################################')
      console.log(agora)
      console.log(ultimo)
      console.log(diferenca)
      console.log('##################################')
*/
      if(zefiDb === null || diferenca > 30){


      const calls = [
        // Balance of token in the LP contract
        {
          address: tokenA,
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Balance of quote token on LP contract
        {
          address: tokenB,
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Total supply of LP tokens
        {
          address: lpAddress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: tokenA,
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: tokenB,
          name: 'decimals',
        },
      ]

      const [
        tokenBalanceLP,
        quoteTokenBlanceLP,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals,
      ] = await multicall(erc20, calls)

      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      // const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

      // Total value in staking in quote token value
      const lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(18))
        .times(new BigNumber(2))

      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      const tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals))
      const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))


      const ZERO = new BigNumber(0)      

      let thisPrice;
      const tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP))
      
      /*
        console.log("####################################")
        console.log(quoteTokenBlanceLP.toNumber())
        console.log(tokenBalanceLP.toNumber())
        console.log(tokenPriceVsQuote.toNumber())
        console.log("####################################")
      */
      

      if(tokenB.toLowerCase() === "0xe9e7cea3dedca5984780bafc599bd69add087d56"){
        thisPrice = tokenPriceVsQuote ? new BigNumber(tokenPriceVsQuote) : ZERO
      }else{
        thisPrice = tokenPriceVsQuote ? bnbBusdPrice.times(tokenPriceVsQuote) : ZERO
      }



        tempData = {
          tokenBalanceLP: new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)),
          quoteTokenBlanceLP: new BigNumber(quoteTokenBlanceLP).div(new BigNumber(10).pow(tokenDecimals)),
          tokenAmount: tokenAmount.toJSON(),
          quoteTokenAmount: quoteTokenAmount.toJSON(),
          lpTotalSupply: new BigNumber(lpTotalSupply).div(new BigNumber(10).pow(tokenDecimals)),
          thisPrice: new BigNumber(thisPrice),
          update: agora,
        }

        // console.log( `Consultou: ${keydb}` )
        sessionStorage.setItem(keydbAll, JSON.stringify(tempData) );  // key and value both should be string or number;
        setData(tempData);

        console.log( 'PRICE UPDATE!!!' )

      }else{
        console.log( 'PRICE Cache...' )
        // tempData = JSON.parse( zefiDb )
      }

     

    };

    fetchData();
  }, [setData, tokenA, tokenB, lpAddress, bnbBusdPrice]);  

  let newData = data

  if(newData === null){
    const datakeydb = `${tokenA}_${tokenB}_${lpAddress}_all`
    const dataDb = sessionStorage.getItem(datakeydb)
    newData = JSON.parse(dataDb)
  }
  return newData
  
}






export const useTotalJson = (): any[] => {
  const farms = useFarms()
  const bnbPrice = usePriceBnbBusd()
  const cakePrice = usePriceCakeBusd()
  let value = new BigNumber(0)
  let debugando
  const pairEntities = []

  // console.log(JSON.stringify(farms))

  for (let i = 0; i < farms.length; i++) {
    const farm = farms[i]
    if (farm.lpTotalInQuoteToken) {
      // debugando = `${farm.pid} : ${farm.lpSymbol} --> ${farm.quoteTokenSymbol} --> ${farm.lpTotalInQuoteToken}`
      if (farm.multiplier !== '0X') {
        pairEntities.push({ id: farm.lpAddresses[56], token0: farm.token.symbol, token1: farm.quoteToken.symbol })
        debugando = `id: ${farm.lpAddresses[56]} --> token0: ${farm.token.symbol} --> token1: ${farm.quoteToken.symbol}`
      }

      // console.log( debugando )

      let val
      if (farm.quoteToken.symbol === 'wBNB') {
        val = bnbPrice.times(farm.lpTotalInQuoteToken)
      } else if (farm.quoteToken.symbol === 'CAKE') {
        val = cakePrice.times(farm.lpTotalInQuoteToken)
      } else if (farm.quoteToken.symbol === 'ZEFI') {
        val = cakePrice.times(farm.lpTotalInQuoteToken)
      } else {
        val = farm.lpTotalInQuoteToken
      }
      value = value.plus(val)
    }
  }

  const obj = {
    tvl: 261098389,
    volume: 76992586,
    bnb: 717048.6336811137,
    data: {
      pairEntities: JSON.parse(JSON.stringify(pairEntities)),
    },
    test: true,
  }

  /*
let name=[];
let age=[];
name.push('sulfikar');
age.push('24');

let ent={};
for(let i=0;i<name.length;i++)
{
  // ent.name = name[i];
  // ent.age = age[i];
}
*/

  // const string = JSON.stringify(pairEntities);
  // const string = JSON.stringify(obj);

  // console.log( string )
  // console.log( JSON.parse(string) )

  return pairEntities
}

// Block
export const useBlock = (): Block => {
  return useSelector((state: State) => state.block)
}
