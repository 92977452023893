import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 1,
    stakingToken: tokens.zefi,
    earningToken: tokens.wzcr,
    contractAddress: {
      97: '',
      56: '0x9048910F4697673B57D751F8E0B3f7Ed71C5CCDF',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.05',
    fee: 0,
  },
  {
    sousId: 2,
    stakingToken: tokens.zefi,
    earningToken: tokens.wksoc,
    contractAddress: {
      97: '',
      56: '0xB4a77309A8e49722fC76063999865A452de0487C',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 998,
    tokenPerBlock: '2',
    fee: 0,
  },  
    {
    sousId: 3,
    stakingToken: tokens.zefi,
    earningToken: tokens.wbtct,
    contractAddress: {
      97: '',
      56: '0x9da9946bE2aDA1262502d4E4b87d8f5aAB22EFA2',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 997,
    tokenPerBlock: '0.12',
    fee: 0,
  },  
    {
    sousId: 4,
    stakingToken: tokens.zefi,
    earningToken: tokens.wiq,
    contractAddress: {
      97: '',
      56: '0x7C9cdF4C8860F92aAb231192A15f355EB54678cA',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 996,
    tokenPerBlock: '0.24',
    fee: 0,
  },   
  {
    sousId: 5,
    stakingToken: tokens.zefi,
    earningToken: tokens.wbnb,
    contractAddress: {
      97: '',
      56: '0x255FDC1d01E5f096eA819096b7cb52181E221794',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 995,
    tokenPerBlock: '0.000015',
    fee: 4,
  },    

  {
    sousId: 6,
    stakingToken: tokens.zefi,
    earningToken: tokens.cake,
    contractAddress: {
      97: '',
      56: '0x2f86e8352E7692A6350971E47f8FF3B1E0089b1B',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 994,
    tokenPerBlock: '0.000139',
    fee: 4,
  },
  {
    sousId: 7,
    stakingToken: tokens.zefi,
    earningToken: tokens.ada,
    contractAddress: {
      97: '',
      56: '0xaa1Da8ea6a5f425AB10772c28bCD614240B6Ea7b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 993,
    tokenPerBlock: '0.0015',
    fee: 4,
  },
  {
    sousId: 8,
    stakingToken: tokens.lpzbobnb,
    earningToken: tokens.wbnb,
    contractAddress: {
      97: '',
      56: '0x1CdcA268d47FAf8036b0D0Bd4Df9d78266cEdB47',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 992,
    tokenPerBlock: '0.000017',
    fee: 0,
  },
  {
    sousId: 9,
    stakingToken: tokens.lpzbobnb,
    earningToken: tokens.cake,
    contractAddress: {
      97: '',
      56: '0x18169F931316efDc615b52EFDc6e1F877584F33A',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 991,
    tokenPerBlock: '0.00033',
    fee: 0,
  },  
  {
    sousId: 10,
    stakingToken: tokens.lpzefibnb,
    earningToken: tokens.wbnb,
    contractAddress: {
      97: '',
      56: '0x5ADF0A49b1b4048F38fA92966730E952c8A8696d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 990,
    tokenPerBlock: '0.00006',
    fee: 0,
  },   

  {
    sousId: 11,
    stakingToken: tokens.lpzefibnb,
    earningToken: tokens.zbo,
    contractAddress: {
      97: '',
      56: '0xa94c9B99Edd5e559e1a7ba02A33fcF81bd2eD2Ca',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 989,
    tokenPerBlock: '0.0028000000',
    fee: 0,
  },     

  {
    sousId: 12,
    stakingToken: tokens.lpzbobnb,
    earningToken: tokens.zbo,
    contractAddress: {
      97: '',
      56: '0xF83236e95c169EA5523e0142Be780D1eb4a88a31',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 988,
    tokenPerBlock: '0.0028000000',
    fee: 0,
  },   

  {
    sousId: 13,
    stakingToken: tokens.lpzefibnb,
    earningToken: tokens.ata,
    contractAddress: {
      97: '',
      56: '0x39e2f1c106a90da59Fd5cAC2d5cF22d90accB1a0',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 987,
    tokenPerBlock: '0.0040',
    fee: 0,
  },   



  {
    sousId: 14,
    stakingToken: tokens.zefi,
    earningToken: tokens.wbnb,
    contractAddress: {
      97: '',
      56: '0xe0857699fa1660C8d983a54561fe7205009df064',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 986,
    tokenPerBlock: '0.0000136',
    fee: 0,
  },    


    {
    sousId: 15,
    stakingToken: tokens.zefi,
    earningToken: tokens.eth,
    contractAddress: {
      97: '',
      56: '0x5bC0d412A9e50b59d292363b95f77F0123612B45',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 985,
    tokenPerBlock: '0.000002',
    fee: 0,
  },     


    {
    sousId: 16,
    stakingToken: tokens.zefi,
    earningToken: tokens.mel,
    contractAddress: {
      97: '',
      56: '0x26dd406b115AD2c782955C8b01065FbF99Bfd554',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 984,
    tokenPerBlock: '0.082',
    fee: 0,
  },   

  /*
  {
    sousId: 8,
    stakingToken: tokens.lpzefibnb,
    earningToken: tokens.wbnb,
    contractAddress: {
      97: '',
      56: '0x76260cCaa3b952f6d5e8c67e15475358aa74d60f',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 990,
    tokenPerBlock: '0.00006',
    fee: 0,
  },

  {
    sousId: 9,
    stakingToken: tokens.lpzbobnb,
    earningToken: tokens.wbnb,
    contractAddress: {
      97: '',
      56: '0x416c84423F23e8E01D80d45876ae7beC5fF088F4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 988,
    tokenPerBlock: '0.000017',
    fee: 0,
  },  
*/



  /* REMOVER AS RECOMPENSAS NO DIA 15 DE JUNHO
    {
    sousId: 9,
    stakingToken: tokens.lpzbobnb,
    earningToken: tokens.btcb,
    contractAddress: {
      97: '',
      56: '0x4dF0FB14F185e21B4a99B139d5005BEa3E2a03A2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 989,
    tokenPerBlock: '0.0000001400',
    fee: 0,
  },
  {
    sousId: 9,
    stakingToken: tokens.zbo,
    earningToken: tokens.btcb,
    contractAddress: {
      97: '',
      56: '0xF98d7a26212a4e4551994fd1b3A911988b30EDeE',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 992,
    tokenPerBlock: '0.000000059',
    fee: 0,
  },   
  {
    sousId: 10,
    stakingToken: tokens.zbo,
    earningToken: tokens.eth,
    contractAddress: {
      97: '',
      56: '0xB4e0eB4A6FA1e19f2f3E0BDA2F4bF49c897389c6',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 991,
    tokenPerBlock: '0.00000088',
    fee: 0,
  },
*/



  /*
    {
    sousId: 6,
    stakingToken: tokens.zefi,
    earningToken: tokens.wbnb,
    contractAddress: {
      97: '',
      56: '0x719c6fe833F9d71CE6acb8A11FeBDf62aF6fC87D',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 994,
    tokenPerBlock: '0.000005',
    fee: 4,
  },        
  {
    sousId: 3,
    stakingToken: tokens.zefi,
    earningToken: tokens.wksoc,
    contractAddress: {
      97: '',
      56: '0x2253153f482ae74745c7C06f329C4ee9004947e2',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '2',
  },    
  */
]

export default pools

// 